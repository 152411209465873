import * as React from "react"
import {
	SimpleForm,
	TextInput,
	Edit,
	required,
	ArrayInput,
	SimpleFormIterator
} from "react-admin"
import { makeStyles } from "@material-ui/core"

import { MediaField } from "../components/mediaField"
import { FormToolbar } from "../components/button"
import { getCustomAddButton } from "../components/button"

const useStyles = makeStyles({
	hidden: {
		display: "none"
	},
	textarea: {
		width: "25%"
	}
})

export const ToolboxEdit = (props) => {
	const classes = useStyles()

	return (
		<Edit {...props}>
			<SimpleForm toolbar={<FormToolbar delete={true} />}>
				<TextInput
					className={classes.textarea}
					multiline 
					label="titre" 
					source="title" 
					validate={required()} 
				/>
				<ArrayInput label="" name="slides" source="slides">
					<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une diapo")}>
						<TextInput className={classes.hidden} disabled source="id" label="Id de la slide" />
						<TextInput
							rows="5"
							className={classes.textarea}
							validate={required()}
							multiline
							source="description"
							label="description"
						/>
						<MediaField 
							label="media"
							className={classes.textarea}
							source="media" 
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Edit>
	)
}
