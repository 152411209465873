import * as React from "react"
import { Card, Box, Typography } from "@mui/material"

export const StatCard = (props) => {
	const { title, subtitle } = props;

	return (
		<Card
			sx={{
				minHeight: 52,
				minWidth: "300px",
				display: "flex",
				justifyContent: "flex-end"
			}}
		>
			<Box
				sx={{
					overflow: "inherit",
					padding: "16px",
					display: "flex"
				}}
			>
				<Box textAlign="right">
					<Typography color="textSecondary">{title}</Typography>
					<Typography variant="h5" component="h2">
						{subtitle}
					</Typography>
				</Box>
			</Box>
		</Card>
	)
}