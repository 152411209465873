import * as React from "react"
import { Create, SimpleForm, TextInput, required } from "react-admin"
import { FormToolbar } from "../components/button"

export const TopicsCreate = (props) => {
	return (
		<Create {...props}>
			<SimpleForm toolbar={<FormToolbar />}>
				<TextInput
					multiline
					label="titre"
					source="title"
					validate={required()}
				/>
			</SimpleForm>
		</Create>
	)
}
