const prose = {
	width: "100%",
	maxWidth: "60ch"
}

const paperSectionTitle = {
	paddingX: "1rem",
	paddingY: ".5rem",
	borderBottom: "1px solid",
	borderColor: "divider",
	marginBottom: 0
}

const datagridButton = {
	minWidth: "auto",
	"& .MuiButton-startIcon": {
		margin: 0
	}
}

const whiteSpaceNowrap = {
	whiteSpace: "nowrap"
}

const columnAuto = {
	width: "auto"
}

const column50 = {
	width: "50%"
}

const column30 = {
	width: "30%"
}

const column25 = {
	width: "25%"
}

const column10 = {
	width: "10%"
}

const columnIsArchived = {
	width: "4em",
	textAlign: "center"
}

const columnButton = {
	width: "2em",
	textAlign: "center"
}

const datagridColumns = {
	"& .column-undefined": columnButton,
	"& .column-civility": column10,
	"& .column-name": column50,
	"& .column-fullName": columnAuto,
	"& .column-firstName": column50,
	"& .column-lastName": column50,
	"& .column-groups": {
		...column25,
		textAlign: "left"
	},
	"& .column-organizations": {
		...column25,
		textAlign: "left"
	},
	"& .column-phoneNumber": {
		...column25,
		...whiteSpaceNowrap
	},
	"& .column-price": {
		...column25,
		...whiteSpaceNowrap,
		textAlign: "left"
	},
	"& .column-email": {
		...column30,
		...whiteSpaceNowrap
	},
	"& .column-createdAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-updatedAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-email-plannedAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-followUp-plannedAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-startTime": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-endTime": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-number": {
		...column10,
		...whiteSpaceNowrap,
		textAlign: "left"
	},
	"& .column-isArchived": columnIsArchived,
	"& .column-patronages": columnIsArchived
}


export default {
	prose,
	column50,
	column30,
	column25,
	column10,
	paperSectionTitle,
	datagridButton,
	datagridColumns
}
