import {makeStyles} from "@material-ui/core"
import {
	AutocompleteArrayInput,
	FormDataConsumer,
	FormTab,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput
} from "react-admin"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	},
	hidden: {
		display: "none"
	}
})

export const ChatPreviousMessage = (props) => {
	const classes = useStyles()

	return (
		<FormTab label="Messages Précédents" {...props}>
			<ReferenceInput name="previousMessageTopic" source="topic" reference="topics" label="Sujet" >
				<SelectInput optionText="title"/>
			</ReferenceInput>
			<FormDataConsumer>
				{({formData}) => {
					return <ReferenceArrayInput
						className={classes.textarea}
						source="previousMessages"
						reference="chats"
						label="Message"
						filter={{topic: formData.previousMessageTopic}}
					>
						<AutocompleteArrayInput optionText="content"/>
					</ReferenceArrayInput>
				}}
			</FormDataConsumer>
		</FormTab>
	)
}