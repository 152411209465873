import * as React from "react"
import { List, Datagrid, TextField, EditButton, CreateButton } from "react-admin"

export const ToolboxList = (props) => (
	<List {...props} pagination={false} actions={<CreateButton label="Ajouter" />}>
		<Datagrid rowClick="edit">
			<TextField source="title" label="Titre" />
			<EditButton label="Editer" />
		</Datagrid>
	</List>
)
