import * as React from "react"
import { useState } from "react"
import { Create, SimpleForm, TextInput, DateInput, required, ImageInput, ImageField } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import { FormToolbar } from "../components/button"
import { getUrl } from "../utils/url"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	}
})

export const NewsCreate = (props) => {
	const classes = useStyles()

	const getOpenGraphData = async (url) => {
		const res = await fetch(
			getUrl("/news/og/ ")+encodeURIComponent(url)
		).then((res) => res.json())

		setTitle(res.ogTitle)
		setDescription(res.ogDescription)
		setSource(res.ogUrl)
		setAuthor(res.twitterCreator)
		setImage(Array.isArray(res.ogImage) ? res.ogImage[0] : res.ogImage)
	}

	const [title, setTitle] = useState()
	const [description, setDescription] = useState()
	const [source, setSource] = useState()
	const [author, setAuthor] = useState()
	const [newsDate, setNewsDate] = useState()
	const [image, setImage] = useState()

	return (
		<Create {...props}>
			<SimpleForm toolbar={<FormToolbar />}>
				<TextInput
					label="url"
					source="url"
					onChange={(e) => getOpenGraphData(e.currentTarget.value)}
					className={classes.textarea}
				/>
				<TitleInput value={title} />
				<DescriptionInput value={description} />
				<SourceInput value={source} />
				<AuthorInput value={author} />
				<NewsDateInput value={newsDate} />
				<NewsImageInput
					onChange={() => {
						setImage(null)
					}}
					value={image}
				/>
			</SimpleForm>
		</Create>
	)
}

const TitleInput = (props) => {
	const classes = useStyles()

	return (
		<TextInput
			className={classes.textarea}
			defaultValue={props.value}
			multiline
			rows="5"
			label="titre"
			source="title"
			validate={required()}
		/>
	)
}

const DescriptionInput = (props) => {
	const classes = useStyles()

	return (
		<TextInput
			className={classes.textarea}
			defaultValue={props.value}
			multiline
			rows="5"
			label="description"
			source="description"
			validate={required()}
		/>
	)
}

const SourceInput = (props) => {
	const classes = useStyles()

	return (
		<TextInput
			defaultValue={props.value}
			className={classes.textarea}
			multiline
			label="source"
			source="source"
			validate={required()}
		/>
	)
}

const AuthorInput = (props) => {
	const classes = useStyles()

	return (
		<TextInput
			defaultValue={props.value}
			className={classes.textarea}
			multiline
			label="auteur"
			source="author"
			validate={required()}
		/>
	)
}

const NewsDateInput = (props) => {
	const classes = useStyles()

	return <DateInput 
		defaultValue={props.value} 
		label="date" 
		source="date" 
		className={classes.textarea}
	/>
}

const NewsImageInput = (props) => {
	const classes = useStyles()

	return (
		<ImageInput
			source="media"
			label="Image de l'information"
			accept="image/*"
			defaultValue={props.value}
			onChange={props.onChange}
			className={classes.textarea}
		>
			<ImageField source="url" title="title" />
		</ImageInput>
	)
}

