import simpleRestProvider from "ra-data-simple-rest"

import { env } from "../utils/env"

const dataProvider = simpleRestProvider(env().apiUrl)

const chatProvider = {
	...dataProvider,
	create: async (resource, params) => {
		const { data } = params

    let linkedMessages = []

    if (data.linkedMessages) {
      linkedMessages = await Promise.all(data.linkedMessages.map(async linkedMessage => {
        return {
          ...linkedMessage,
          mediaContent: await buildMedia(linkedMessage, "mediaContent")
        }
      }))
    }

    return dataProvider.create(resource, {
      ...params,
      data: {
			  ...data,
			  mediaContent: await buildMedia(data, "mediaContent"),
        linkedMessages
      }
		})
	},
	update: async (resource, params) => {
		const { data } = params

    let linkedMessages = []

    if (data.linkedMessages) {
      linkedMessages = await Promise.all(data.linkedMessages.map(async linkedMessage => {
        return {
          ...linkedMessage,
          mediaContent: await buildMedia(linkedMessage, "mediaContent")
        }
      }))
    }

		return dataProvider.update(resource, {
			...params,
      data: {
        ...data,
        mediaContent: await buildMedia(data, "mediaContent"),
        linkedMessages
      }
		})
	}
}

const buildMedia = async (element, mediaKey = "media") => {
  if (!element || !element[mediaKey]) {
    return null
  }

  if (element[mediaKey].id) {
    return {
      id: element[mediaKey].id,
      credit: element[mediaKey].credit || null,
      alt: element[mediaKey].alt || null
    }
  }

  return {
    src: await convertFileToBase64(element[mediaKey]),
    title: element[mediaKey].title,
    credit: element.credit || null,
    alt: element.alt || null
  }
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => resolve(reader.result)
		reader.onerror = reject

		reader.readAsDataURL(file.rawFile)
	})

export default chatProvider