export const env = () => {
  let apiUrl = process.env.REACT_APP_API_URL
  let frontendUrl = process.env.REACT_APP_FRONTEND_URL

  if (process.env.NODE_ENV === "production") {
    apiUrl = window.REACT_APP_API_URL
    frontendUrl = window.REACT_APP_FRONTEND_URL
  }

  return {
    apiUrl,
    frontendUrl
  }
}
