import * as React from "react"
import { Edit, SimpleForm, TextInput } from "react-admin"
import { makeStyles } from "@material-ui/core"
import { MediaField } from "../components/mediaField"
import { FormToolbar } from "../components/button"

const useStyles = makeStyles({
	hidden: {
		display: "none"
	},
	textarea: {
		display: "flex",
		width: "25%"
	}
})

export const CharacterEdit = (props) => {
	const classes = useStyles()

	return (
		<Edit {...props}>
			<SimpleForm toolbar={<FormToolbar />}>
				<TextInput className={classes.hidden} disabled source="id" />
				<TextInput 
					label="nom du personnage" 
					source="name"
					className={classes.textarea}
				/>
				<MediaField 
					label="image du personnage" 
					className={classes.textarea}
					source="media"
					alt={true}
					altSource="media.alt"
					altLabel="Description du personnage"
					altClassname={classes.textarea} 
				/>
			</SimpleForm>
		</Edit>
	)
}