import * as React from 'react';
import { DashboardMenuItem, Menu, MenuItemLink } from 'react-admin';
import FaceIcon from "@material-ui/icons/Face"
import MessageIcon from "@material-ui/icons/Message"
import SearchIcon from "@material-ui/icons/Search"
import TitleIcon from "@mui/icons-material/Title"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import BuildIcon from "@material-ui/icons/Build"
import SpeakerNotes from "@material-ui/icons/SpeakerNotes"
import AccountCircle from "@material-ui/icons/AccountCircle"
import PersonIcon from "@material-ui/icons/Person"

export const CustomMenu = (props) => {
	return (
		<Menu {...props}>
			<DashboardMenuItem />
			<MenuItemLink to="/enigmas" primaryText="Enigmes" leftIcon={<SearchIcon />}/>
			<MenuItemLink to="/characters" primaryText="Personnages" leftIcon={<FaceIcon />}/>
			<MenuItemLink to="/chats" primaryText="Messages" leftIcon={<MessageIcon />}/>
			<MenuItemLink to="/news" primaryText="News" leftIcon={<AnnouncementIcon />}/>
			<MenuItemLink to="/toolbox" primaryText="Boite à outils" leftIcon={<BuildIcon />}/>
			<MenuItemLink to="/topics" primaryText="Sujet des messages" leftIcon={<TitleIcon />}/>
			<MenuItemLink to="/recaps/1" primaryText="Recap de fin de partie" leftIcon={<SpeakerNotes />}/>
			<MenuItemLink to="/avatars" primaryText="Avatars" leftIcon={<AccountCircle />}/>
			<MenuItemLink to="/users" primaryText="Utilisateurs" leftIcon={<PersonIcon />}/>
		</Menu>
	);
};