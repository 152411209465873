import { makeStyles } from "@material-ui/core"
import {
	ArrayInput,
	FormTab,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton } from "../../components/button"
import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		display:  "flex",
		width: "25%"
	},
	hidden: {
		display: "none"
	}
})

export const EnigmaExplanation = (props) => {
	const classes = useStyles()

	return (
		<FormTab label="Explication" {...props}>
			<ArrayInput
				name="explanations"
				source="explanations"
				label=""
			>
				<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une explication")}>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id du message"
					/>
					<TextInput
						className={classes.textarea}
						multiline
						rows="5"
						source="content"
						label="Text du message"
					/>
					<MediaField
						source="mediaContent"
						label="media"
						className={classes.textarea}
						credit={true}
						creditClassname={classes.textarea}
						creditSource="credit"
						creditLabel="Crédit de l'image"
						alt={true}
						altClassname={classes.textarea}
						altSource="alt"
						altLabel="Alt de l'image"
					/>
					<ReferenceInput
						source="character"
						reference="characters"
						label="Personnage"
						className={classes.textarea}
					>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
