import * as React from "react"
import { List, Datagrid, TextField, CreateButton } from "react-admin"

export const TopicsList = (props) => (
	<List {...props} 
		pagination={false} 
		actions={<CreateButton label="Ajouter" />} 
		
	>
		<Datagrid bulkActionButtons={false} rowClick="edit">
			<TextField source="title" label="Titre" />
		</Datagrid>
	</List>
)
