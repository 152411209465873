import { makeStyles } from "@material-ui/core"
import { DateInput, RadioButtonGroupInput, TextInput } from "react-admin"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
		width: "25%"
	}
})

export const EnigmaInstagramData = () => {
	const classes = useStyles()

	return (
		<>
			<RadioButtonGroupInput
				className={classes.textarea}
				source="data.certified"
				label="Certifé?"
				choices={[
					{ id: true, name: "oui" },
					{ id: false, name: "non" }
				]}
			/>
			<TextInput
				className={classes.textarea}
				source="data.likes"
				label="Nombre de j'aime ou de vues"
			/>
			<DateInput className={classes.textarea} source="data.date" label="Date" />
		</>
	)
}
