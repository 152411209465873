import * as React from "react"
import {
	Edit,
	TabbedForm,
	SaveButton,
	Toolbar,
} from "react-admin"
import { RecapSuccessMessages } from "./recapMessages/recapSuccessMessages"
import { RecapFairMessages } from "./recapMessages/recapFairMessages"
import { RecapFailMessages } from "./recapMessages/recapFailMessages"
import { RecapTimeoverMessages } from "./recapMessages/recapTimeoverMessages"

export const RecapEdit = (props) => {
	return (
		<Edit {...props}>
			<TabbedForm toolbar={<Toolbar {...props} ><SaveButton /></Toolbar>} redirect={() => "/recaps/1"}>
				<RecapSuccessMessages />
				<RecapFairMessages />
				<RecapFailMessages />
				<RecapTimeoverMessages />
			</TabbedForm>
		</Edit>
	)
}
