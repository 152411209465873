import { makeStyles } from "@material-ui/core"
import { FormTab, RadioButtonGroupInput, ReferenceInput, required, SelectInput, TextInput } from "react-admin"

import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	}
})

export const ChatCommonData = (props) => {
	const classes = useStyles()
  
  return (
    <FormTab label="Général" {...props}>
      <ReferenceInput 
        source="topic" 
        reference="topics" 
        label="Sujet"
        className={classes.textarea}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput
        className={classes.textarea}
        multiline
        rows="5"
        source="content"
        label="Texte du message"
      />
      <MediaField
				source="mediaContent"
				label="Message sous format audio ou vidéo"
				className={classes.textarea}
				altClassname={classes.textarea}
			/>
      <RadioButtonGroupInput
				className={classes.textarea}
				label="Mettre en avant le message"
				source="important"
        defaultValue={false}
				choices={[
					{ id: true, name: "oui" },
					{ id: false, name: "non" }
				]}
			/>
      <ReferenceInput
        source="character"
        reference="characters"
        label="Personnage"
        className={classes.textarea}
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </FormTab>
  )
}