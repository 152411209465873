import { getUrl } from "./utils/url"

const authProvider = {
	login: (credentials) => {
		const { username, password } = credentials

		const request = new Request(
			getUrl("/login"),
			{
				method: "POST",
				body: JSON.stringify({ username, password }),
				headers: new Headers({ "Content-Type": "application/json" })
			}
		)

		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}

				return response.json()
			})
			.then(({ token }) =>  localStorage.setItem("token", token))
	},

	logout: () => {
		localStorage.removeItem("token")

		return Promise.resolve()
	},

	checkAuth: () => {
		return localStorage.getItem("token")
			? Promise.resolve()
			: Promise.reject(new Error())
	},

	checkError: (error) => {
		const { status } = error

		if (status === 401 || status === 403) {
			localStorage.removeItem("token")

			return Promise.reject(new Error())
		}

		return Promise.resolve()
	},

	getPermissions: () => {
		return localStorage.getItem("token")
			? Promise.resolve()
			: Promise.reject(new Error())
	}
}

export default authProvider
