import AddIcon from '@material-ui/icons/AddCircleOutline'
import { Button, DeleteButton, SaveButton, Toolbar } from 'react-admin'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'

export const getCustomAddButton = (label) => {
  return (
    <Button size="small" label={label}>
      <AddIcon/>
    </Button>
  )
}

export const getCustomRemoveButton = (label) => {
  return (
    <Button size="small" label={label}>
      <CloseIcon/>
    </Button>
  )
}

export const FormToolbar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton label="Enregistrer"/>
			{ props.delete && <DeleteButton label="Supprimer"/> }
		</Toolbar>
	)
}