import {
	List,
	Datagrid,
	TextField,
	EditButton,
	CreateButton
} from "react-admin"

export const EnigmaList = (props) => (
	<List {...props} pagination={false} actions={<CreateButton label="Ajouter" />}>
		<Datagrid>
			<TextField source="title" label="Titre de l'énigme" />
			<EditButton label="Editer"/>
		</Datagrid>
	</List>
)
