import { makeStyles } from "@material-ui/core"
import { ArrayInput, FormTab, SelectInput, SimpleFormIterator, TextInput } from "react-admin"

import { getCustomAddButton, getCustomRemoveButton } from "../../components/button"
import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	},
  hidden: {
    display: "none"
  }
})

export const ChatLinkedMessage = (props) => {
	const classes = useStyles()

  return (
    <FormTab label="Messages Suivants" {...props}>
      <ArrayInput name="linkedMessages" source="linkedMessages" label="">
        <SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter un message")} removeButton={getCustomRemoveButton("Supprimer")}>
          <TextInput source="id" className={classes.hidden} disabled />
          <TextInput
            className={classes.textarea}
            multiline
            rows="5"
            source="content"
            label="Texte du message"
          />
          <MediaField
            source="mediaContent"
            label="Message sous format audio ou vidéo"
            className={classes.textarea}
            altClassname={classes.textarea}
          />
          <ArrayInput
            name="conditions"
            source="conditions"
            label=""
          >
            <SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une condition")} removeButton={getCustomRemoveButton("Supprimer")}>
              <TextInput source="id" className={classes.hidden} disabled />
              <SelectInput
                label="Type de condition"
                source="type"
                className={classes.textarea}
                choices={[
                  { id: "event", name: "event" },
                  { id: "delay", name: "delay" }
                ]}
              />
              <TextInput 
                label="Valeur de la condition" 
                source="value"
                className={classes.textarea}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  )
}
