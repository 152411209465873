import { makeStyles } from "@material-ui/core"
import {
	ArrayInput,
	AutocompleteArrayInput,
	FormDataConsumer,
	FormTab,
	ReferenceArrayInput,
	SimpleFormIterator,
	TextInput,
	AutocompleteInput,
	ReferenceInput
} from "react-admin"
import { getCustomAddButton, getCustomRemoveButton } from "../../components/button"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	}
})

export const ChatAnswer = (props) => {
	const classes = useStyles()

  return (
    <FormTab label="Réponses" {...props}>
      <TextInput 
        source="delay" 
        label="Délai d'affichage des réponses" 
        className={classes.textarea}
      />
      <ArrayInput name="answers" source="answers" label="">
        <SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une réponse")} removeButton={getCustomRemoveButton("Supprimer")}>
          <TextInput
            className={classes.textarea}
            multiline
            rows="5"
            source="text"
            label="Texte de la réponse"
          />
          <span>Ajouter un message suivant existant :</span>
          <ReferenceInput name="nextMessageTopic" source="topic" reference="topics" label="Sujet">
            <AutocompleteInput optionText="title"/>
          </ReferenceInput>
          <FormDataConsumer>
            {({ getSource, formData }) => {
              return <ReferenceArrayInput
                className={classes.textarea}
                source={getSource("events.messages")}
                reference="chats"
                label="Message"
                filter={{ topic: formData.nextMessageTopic }}
              >
                <AutocompleteArrayInput optionText="content" />
              </ReferenceArrayInput>
            }}
          </FormDataConsumer>
          <span>Ajouter un nouveau message suivant:</span>
          <TextInput
            className={classes.textarea}
            multiline
            rows="5"
            source="nextMessage"
            label="Texte du nouveau message"
          />

					<span>Déclencher une toolbox:</span>
					<ReferenceArrayInput
						className={classes.textarea}
						source="events.toolbox"
						reference="toolbox"
						label="Toolbox"
					>
						<AutocompleteArrayInput optionText="title"/>
					</ReferenceArrayInput>
					<span>Déclencher un événement Personnalisé:</span>
					<ArrayInput
						source="events.customs"
						label=""
					>
						<SimpleFormIterator disableReordering>
							<TextInput label="Evènement personnalisé" className={classes.textarea} />
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}