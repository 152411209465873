import * as React from "react"
import { EditButton, ListBase, Title, Button, Link } from "react-admin"
import { Fragment, useState } from "react"
import {
	Box,
	List,
	ListItem,
	ListItemSecondaryAction,
	Collapse,
	Card
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { useGetList } from "react-admin"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	cursor: {
		cursor: "pointer"
	}
})

export const ChatList = (props) => (
	<ListBase perPage={1000} {...props}>
		<Box marginTop="1em">
			<Card>
				<Tree />
			</Card>
		</Box>
	</ListBase>
)

const Tree = () => {
	const topicsData = useGetList("topics", {}, {}, {
		enigma: true
	})
	const chatsData = useGetList("chats")

	const [openChildren, setOpenChildren] = useState([])
	const toggleNode = (node) => {
		setOpenChildren((state) => {
			if (state.includes(node.id)) {
				return [
					...state.splice(0, state.indexOf(node.id)),
					...state.splice(state.indexOf(node.id) + 1, state.length)
				]
			} else {
				return [...state, node.id]
			}
		})
	}

	const showNextMessage = (answer) => {
		answer.events.messages.forEach((message) => {
			document.getElementById(message).classList.add("Mui-selected")
		})
	}

	if (!topicsData.loading && !chatsData.loading) {
		const topics = topicsData.ids.map((id) => topicsData.data[id])
		const messages = chatsData.ids.map((id) => chatsData.data[id])

		const isRoot = (message) => {
			let isRoot = true
			messages.forEach((msg) => {
				if (msg.linkedMessages) {
					msg.linkedMessages.forEach((linkedMessage) => {
						if (linkedMessage.childId === message.id && messages.find((msg) => msg.id === linkedMessage.parentId).topic === message.topic) {
							isRoot = false
						}
					})
				}
			})

			return isRoot
		}

		const getChildNodes = (root) => {
			if (root.title) {
				return messages.filter((message) => {
					return message.topic === root.id && isRoot(message)
				})
			}

			if (typeof root.linkedMessages === "undefined") {
				return []
			}

			const childs = []

			if (root.linkedMessages.length > 0) {
				root.linkedMessages.forEach((linkedMessage) => {
					const childMessage = messages.find((message) => message.id === linkedMessage.childId)
					if (childMessage.topic === root.topic) {
						childs.push(childMessage)
					}
				})
			}

			if (root.answers.length > 0) {
				root.answers.forEach((answer) => {
					childs.push(answer)
				})
			}

			return childs
		}

		return (
			<List>
				<Title />
				{topics.map((topic) => (
					<SubTree
						key={topic.id}
						root={topic}
						getChildNodes={getChildNodes}
						openChildren={openChildren}
						toggleNode={toggleNode}
						showNextMessage={showNextMessage}
						level={1}
					/>
				))}
			</List>
		)
	}

	return null
}

const SubTree = ({
	level,
	root,
	getChildNodes,
	openChildren,
	toggleNode,
	showNextMessage
}) => {
	const childNodes = getChildNodes(root)
	const hasChildren = childNodes.length > 0
	const open = openChildren.includes(root.id)
	const classes = useStyles()

	return (
		<Fragment>
			<ListItem
				style={{ paddingLeft: level * 16 }}
				id={root.content ? root.id : ""}
				className={hasChildren ? classes.cursor : ""}
			>
				{hasChildren && open && <ExpandLess />}
				{hasChildren && !open && <ExpandMore />}
				{!hasChildren && <div style={{ width: 24 }}>&nbsp;</div>}
				{root.text ? (
					<div onClick={() => showNextMessage(root)}>{root.text}</div>
				) : (
					<>
						{root.title ? (
							<div>
								<div onClick={() => hasChildren && toggleNode(root)}>
									{root.title}
								</div>
								<ListItemSecondaryAction>
									<Button
										component={Link}
										label="Ajouter un message"
										to={{
											pathname: "/chats/create",
											state: { record: { topic: root.id } }
										}}
									/>
								</ListItemSecondaryAction>
							</div>
						) : (
							<div>
								<div onClick={() => hasChildren && toggleNode(root)}>
									{root.id} - {root.content}
								</div>
								<ListItemSecondaryAction>
									<EditButton label="Editer" record={root} basePath="/chats" />
								</ListItemSecondaryAction>
							</div>
						)}
					</>
				)}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{childNodes.map((node) => (
						<SubTree
							key={node.id}
							root={node}
							getChildNodes={getChildNodes}
							openChildren={openChildren}
							toggleNode={toggleNode}
							showNextMessage={showNextMessage}
							level={level + 1}
						/>
					))}
				</List>
			</Collapse>
		</Fragment>
	)
}
