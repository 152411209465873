import * as React from "react"
import { Edit, SimpleForm, TextInput } from "react-admin"
import { makeStyles } from "@material-ui/core"
import { MediaField } from "../components/mediaField"
import { FormToolbar } from "../components/button"

const useStyles = makeStyles({
	hidden: {
		display: "none"
	},
	textarea: {
		display: "flex",
		width: "25%"
	}
})

export const AvatarEdit = (props) => {
	const classes = useStyles()

	return (
		<Edit {...props}>
			<SimpleForm toolbar={<FormToolbar delete={true} />}>
				<TextInput className={classes.hidden} disabled source="id" />
				<MediaField 
					label="avatar"
					className={classes.textarea}
					source="media" 
					accept="image/*"
					alt={true}
					altSource="media.alt"
					altLabel="Description de l'avatar"
					altClassname={classes.textarea}
				/>
			</SimpleForm>
		</Edit>
	)
}