import * as React from "react"
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	ArrayInput,
	SimpleFormIterator
} from "react-admin"
import { makeStyles } from "@material-ui/core/styles"

import { MediaField } from "../components/mediaField"
import { FormToolbar } from "../components/button"
import { getCustomAddButton } from "../components/button"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	}
})

export const ToolboxCreate = (props) => {
	const classes = useStyles()

	return (
		<Create {...props}>
			<SimpleForm toolbar={<FormToolbar />}>
				<TextInput
					className={classes.textarea}
					multiline
					label="titre"
					source="title"
					validate={required()}
				/>
				<ArrayInput label="" name="slides" source="slides">
					<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une diapo")}>
						<TextInput
							rows="5"
							className={classes.textarea}
							validate={required()}
							multiline
							source="description"
							label="description"
						/>
						<MediaField 
							label="media"
							className={classes.textarea}
							source="media" 
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	)
}
