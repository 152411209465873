import {
	FileField,
	FileInput,
	FormDataConsumer,
	TextInput
} from "react-admin"
import { get } from "lodash"
import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
	image: {
		"max-width": "200px"
	}
})

export const MediaField = (props) => {
	const classes = useStyles()

	return (
		<>
			<>
				<FileInput
					source={props.source}
					label={props.label}
					defaultValue={props.value}
					onChange={props.onChange}
					name={props.name}
					accept={props.accept || "image/*,audio/mpeg,video/mp4"}
					className={props.className}
				>
					<FileField source="url" title="title" />
				</FileInput>
				<FormDataConsumer>
					{({ formData }) => {
						const media = props.value ? get(props.value, props.id) : get(formData, props.source)
						if (media) {
							if ((media.rawFile || {}).type === "video/mp4" || media.type === "video") {
								return (
									<>
										<video controls src={media.url}></video>
									</>
								)
							}
							else if ((media.rawFile || {}).type === "audio/mpeg" || media.type === "audio") {
								return (
									<>
										<audio controls src={media.url}></audio>
									</>
								)
							} else {
								return (
									<>
										<img src={media.url} alt={media.title} className={classes.image} />
									</>
								)
							}
						}
					}}
				</FormDataConsumer>
			</>
			{props.credit && (
				<TextInput
					className={props.creditClassname}
					source={props.creditSource}
					label={props.creditLabel}
				/>
			)}
			{props.alt && (
				<TextInput
					className={props.altClassname}
					source={props.altSource}
					label={props.altLabel}
					rows="5"
					multiline
				/>
			)}
		</>
	)
}