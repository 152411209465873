import simpleRestProvider from "ra-data-simple-rest"

import { env } from "../utils/env"

const dataProvider = simpleRestProvider(env().apiUrl)

const recapProvider = {
	...dataProvider,
	update: async (resource, params) => {
		const { data } = params

		return dataProvider.update(resource, {
			...params,
			data: {
				...data,
				successMessages: await buildMessages(data.successMessages, "mediaContent"),
				fairMessages: await buildMessages(data.fairMessages, "mediaContent"),
				failMessages: await buildMessages(data.failMessages, "mediaContent"),
				timeoverMessages: await buildMessages(data.timeoverMessages, "mediaContent")
			}
		})
	}
}

const buildMessages = async (messages) => {
	return messages
		? await Promise.all(messages.map(async (message) => (
			{
				...message,
				mediaContent: await buildMedia(message)
			}
		)))
		: null
}

const buildMedia = async (element) => {
	if (!element || !element.mediaContent) {
		return null
	}

	if (element.mediaContent.id) {
		return {
			id: element.mediaContent.id,
			credit: element.mediaContent.credit || null,
			alt: element.mediaContent.alt || null
		}
	}

	return {
		src: await convertFileToBase64(element.mediaContent),
		title: element.mediaContent.title,
		credit: element.credit || null,
		alt: element.alt || null
	}
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => resolve(reader.result)
		reader.onerror = reject

		reader.readAsDataURL(file.rawFile)
	})

export default recapProvider