import * as React from "react"
import { SimpleForm, TextInput, Edit, required } from "react-admin"
import { FormToolbar } from "../components/button"

export const TopicsEdit = (props) => (
	<Edit {...props}>
		<SimpleForm toolbar={<FormToolbar delete={false} />}>
			<TextInput multiline label="titre" source="title" validate={required()} />
		</SimpleForm>
	</Edit>
)
