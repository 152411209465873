import { Edit, FormTab, TabbedForm, FormDataConsumer, TabbedFormTabs } from "react-admin"

import { EnigmaArticleData } from "./enigmaSpecificData/enigmaArticleData"
import { EnigmaTweetData } from "./enigmaSpecificData/enigmaTweetData"
import { EnigmaFacebookData } from "./enigmaSpecificData/enigmaFacebookData"
import { EnigmaInstagramData } from "./enigmaSpecificData/enigmaInstagramData"
import { EnigmaTiktokData } from "./enigmaSpecificData/enigmaTiktokData"
import { EnigmaYoutubeData } from "./enigmaSpecificData/enigmaYoutubeData"
import { EnigmaEvents } from "./enigmaEvents/enigmaEvents"
import { EnigmaCommonData } from "./enigmaCommonData/enigmaCommonData"
import { EnigmaCaption } from "./enigmaCaption/enigmaCaption"
import { EnigmaIntro } from "./enigmaMessage/enigmaIntro"
import { EnigmaOutro } from "./enigmaMessage/enigmaOutro"
import { EnigmaClue } from "./enigmaClues/enigmaClue"
import { EnigmaCaptionClue } from "./enigmaClues/enigmaCaptionClue"
import { EnigmaTemporalClue } from "./enigmaClues/enigmaTemporalClue"
import { FormToolbar } from "../components/button"
import { EnigmaExplanation } from "./enigmaExplanation/enigmaExplanation"

export const EnigmaEdit = (props) => {
	return (
		<Edit {...props}>
			<TabbedForm tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} toolbar={<FormToolbar />}>
				<FormTab label="Enigmes">
					<EnigmaCommonData />
					<FormDataConsumer>
						{({ formData }) => {
							switch (formData.type) {
								case "website":
									return <EnigmaArticleData {...props} />
								case "tweet":
									return <EnigmaTweetData {...props} />
								case "facebook":
									return <EnigmaFacebookData {...props} />
								case "instagram":
									return <EnigmaInstagramData {...props} />
								case "tiktok":
									return <EnigmaTiktokData {...props} />
								case "youtube":
									return <EnigmaYoutubeData {...props} />
								default:
									return null
							}
						}}
					</FormDataConsumer>
				</FormTab>
				<EnigmaCaption />
				<EnigmaIntro />
				<EnigmaOutro />
				<EnigmaClue />
				<EnigmaCaptionClue />
				<EnigmaTemporalClue />
				<EnigmaEvents />
				<EnigmaExplanation />
			</TabbedForm>
		</Edit>
	)
}
