import { makeStyles } from "@material-ui/core"
import { FormTab, ReferenceInput, SelectInput, TextInput } from "react-admin"
import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
		width: "25%"
	},
	hidden: {
		display: "none"
	}
})

export const EnigmaOutro = (props) => {
	const classes = useStyles()

	return (
		<FormTab label="Message d'outro" {...props}>
			<TextInput
				className={classes.hidden}
				disabled
				source="outro.id"
				label="Id du message"
			/>
			<TextInput
				className={classes.textarea}
				multiline
				rows="5"
				source="outro.content"
				label="Text du message"
			/>
			<MediaField
				name="outro.mediaContent"
				source="outro.mediaContent"
				label="media"
				className={classes.textarea}
				credit={true}
				creditClassname={classes.textarea}
				creditSource="outro.credit"
				creditLabel="Crédit de l'image"
				alt={true}
				altClassname={classes.textarea}
				altSource="outro.alt"
				altLabel="Alt de l'image"
			/>
			<ReferenceInput
				source="outro.character"
				reference="characters"
				label="Personnage"
				className={classes.textarea}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
		</FormTab>
	)
}
