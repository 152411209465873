import { env } from "./env"

export const getUrl = (route, frontendUrl = false) => {
  let url = `${env().apiUrl}`

  if (frontendUrl === true) {
    url = `${env().frontendUrl}`
  }

  return `${url}${route}`
}