import {
	AutocompleteArrayInput,
	FormTab,
	ReferenceArrayInput,
	ArrayInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton } from "../../components/button"

export const EnigmaEvents = (props) => {
	return (
		<FormTab label="Evénements" {...props}>
			<ReferenceArrayInput
				name="events.messages"
				source="events.messages"
				reference="chats"
				label="Message"
			>
				<AutocompleteArrayInput optionText="content" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				source="events.toolbox"
				reference="toolbox"
				label="Toolbox"
			>
				<AutocompleteArrayInput optionText="title" />
			</ReferenceArrayInput>
			<ArrayInput
				source="events.customs"
				label=""
			>
				<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter un événement personnalisé")}>
					<TextInput label="Evènement personnalisé" />
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
