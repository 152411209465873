import * as React from "react"
import { SimpleForm, TextInput, Edit, DateInput, required } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import { MediaField } from "../components/mediaField"
import { FormToolbar } from "../components/button"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	},
	hidden: {
		display: "none"
	}
})

export const NewsEdit = (props) => {
	const classes = useStyles()

	return (
		<Edit {...props}>
			<SimpleForm toolbar={<FormToolbar />}>
				<TextInput 
					className={classes.hidden} 
					disabled 
					source="id"
				/>
				<TextInput
					className={classes.textarea}
					rows="5"
					multiline
					label="titre"
					source="title"
					validate={required()}
				/>
				<TextInput
					className={classes.textarea}
					rows="5"
					multiline
					label="description"
					source="description"
					validate={required()}
				/>
				<TextInput
					className={classes.textarea}
					multiline
					label="source"
					source="source"
					validate={required()}
				/>
				<TextInput
					className={classes.textarea}
					multiline
					label="auteur"
					source="author"
					validate={required()}
				/>
				<DateInput
					className={classes.textarea}
					label="date" 
					source="date" 
					validate={required()} 
				/>
				<MediaField 
					source="media" 
					label="Image de l'information"
					className={classes.textarea}
					accept="image/*"
				/>
			</SimpleForm>
		</Edit>
	)
}
