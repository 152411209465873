import simpleRestProvider from "ra-data-simple-rest"

import { env } from "../utils/env"

const dataProvider = simpleRestProvider(env().apiUrl)

const enigmaProvider = {
	...dataProvider,
	create: async (resource, params) => {
		const { data } = params

		return dataProvider.create(resource, {
			...params,
			data: {
				...data,
				data: await buildData(data.data),
				caption: await buildCaption(data.caption),
				intro: await buildMedia(data.intro, "mediaContent"),
				outro: await buildMedia(data.outro, "mediaContent"),
				captionClue: await buildClue(data.captionClue),
				enigmaClue: await buildClue(data.enigmaClue),
				temporalClue: await buildClue(data.temporalClue),
				explanations: await buildExplanations(data.explanations)
			}
		})
	},
	update: async (resource, params) => {
		const { data } = params
		return dataProvider.update(resource, {
			...params,
			data: {
				...data,
				data: await buildData(data.data),
				caption: await buildCaption(data.caption),
				intro: await buildMedia(data.intro, "mediaContent"),
				outro: await buildMedia(data.outro, "mediaContent"),
				captionClue: await buildClue(data.captionClue),
				enigmaClue: await buildClue(data.enigmaClue),
				temporalClue: await buildClue(data.temporalClue),
				explanations: await buildExplanations(data.explanations)
			}
		})
	}
}

const buildCaption = async (caption) => {
	if (!caption) return null

	return {
		...await buildMedia(caption),
		choices: caption.choices
			? await Promise.all(caption.choices.map(async (choice) => (
				{
					...choice,
					feedback: await buildMedia(choice.feedback)
				}
			)))
			: null
	}
}

const buildClue = async (clue) => {
	return clue ? { ...clue, advice: await buildMedia(clue.advice, "mediaContent") } : null
}

const buildData = async (data) => {
	if (!data) {
		return null
	}

	const { media } = await buildMedia(data)
	const { avatar } = await buildMedia(data, "avatar")
	const { description } = await buildMedia(data, "description")

	return {
		...data,
		avatar,
		media,
		description
	}
}

const buildExplanations = async (explanations) => {
	return explanations
		? await Promise.all(explanations.map((explanation) => buildMedia(explanation, "mediaContent")))
		: null
}

const buildMedia = async (element, mediaKey = "media") => {
	if (element) {
		if (element[mediaKey]) {
			if (element[mediaKey].id) {
				return {
					...element,
					[mediaKey]: {
						id: element[mediaKey].id,
						credit: element[mediaKey].credit || null,
						alt: element[mediaKey].alt || null
					}
				}
			}
			return {
				...element,
				[mediaKey]: {
					src: await convertFileToBase64(element[mediaKey]),
					title: element[mediaKey].title,
					credit: element[mediaKey].credit || null,
					alt: element[mediaKey].alt || null
				}
			}
		}
		return { ...element, [mediaKey]: null }
	}
	return null
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => resolve(reader.result)
		reader.onerror = reject

		reader.readAsDataURL(file.rawFile)
	})

export default enigmaProvider
