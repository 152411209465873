import * as React from "react"
import numeral from "numeral"
import dayjs from "dayjs"
import { List, FunctionField, Datagrid, useGetList, TextField, DateInput } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import { StatCard } from "./components/statCard"
import { StatChart } from "./components/statChart"

const weekOfYear = require("dayjs/plugin/weekOfYear")
const duration = require('dayjs/plugin/duration')
dayjs.extend(weekOfYear)
dayjs.extend(duration)
dayjs.duration(100)

const useStyles = makeStyles({
	dashboard: {
		display: "flex",
		"flex-wrap": "wrap",
		gap: "15px"
	},
	wrapper: {
		display: "flex",
		"flex-wrap": "wrap",
		justifyContent: "space-evenly"
	},
	chart: {
		marginTop: "2rem",
		maxWidth: "800px",
		maxHeight: "400px"
	}
})

export const Dashboard = ()=> {
	const currentDate = new Date();
	currentDate.setHours(23,59,59,999);
	
	const classes = useStyles()
	const response = useGetList("games")
	const games = Object.values(response.data).filter((game) => game.isDone)
	const fakeProps = {
		basePath: "/enigmas",
		hasCreate: false,
		hasEdit: false,
		hasList: true,
		hasShow: false,
		history: {},
		location: { pathname: "/", search: "", hash: "", state: undefined },
		match: { path: "/", url: "/", isExact: true, params: {} },
		options: {},
		permissions: null,
		resource: "enigmas",
		filter: {startDate: 1553245557, endDate: currentDate.getTime()},
	}

	const getPercent = (value, total) => {
		return numeral(value).divide(total || 1).multiply(100).format("0.00")
	}

	const getAverage = (value, total) => {
		return numeral(value).divide(total || 1).format("0.00")
	}

	const getTime = (value, total) => {
		const average = numeral(value).divide(total || 1).value()
		return dayjs.duration(average, "seconds").format("HH:mm:ss")
	}

	const getStatisticsSuccessTotal = (keyName) => (enigma) => {
		return enigma.statistics.reduce((accumulator, statistics) => {
			return statistics[keyName] ? numeral(accumulator).add(1).value() : accumulator
		}, 0)
	}

	const getStatisticsTotal = (keyName) => (enigma) => {
		return enigma.statistics.reduce((accumulator, statistics) => {
			return numeral(accumulator).add(statistics[keyName]).value()
		}, 0)
	}

	const getAverageEnigmaTime = (enigma) => {
		const timeTotal = getStatisticsTotal("time")(enigma)
		return `${getTime(timeTotal, enigma.statistics.length)}`
	}

	const getAveragePhaseSuccess = (keyName) => (enigma) => {
		const successTotal = getStatisticsSuccessTotal(keyName)(enigma)
		return `${getPercent(successTotal, enigma.statistics.filter((statistics) => statistics[keyName] !== null).length)}%`
	}

	const getAveragePhaseFailure = (keyName) => (enigma) => {
		const successTotal = getStatisticsSuccessTotal(keyName)(enigma)
		const failureTotal = numeral(enigma.statistics.filter((statistics) => statistics[keyName] !== null).length).subtract(successTotal).value()
		
		return `${getPercent(failureTotal, enigma.statistics.filter((statistics) => statistics[keyName] !== null).length)}%`
	}

	const getAveragePhaseClue = (keyName) => (enigma) => {
		const firstPhaseClueTotal = getStatisticsTotal(keyName)(enigma)
		return `${getAverage(firstPhaseClueTotal, enigma.statistics.length)}`
	}

	const time = games.reduce((total, game) => game.time + total, 0)
	const averageTime = getTime(time, games.length)
	const enigmaTotal = games.reduce((total, game) => game.statistics.length + total, 0)
	const averageEnigmaTotal = getAverage(enigmaTotal, games.length)
	const wonGames = games.filter((game) => game.isWon)
	const averageSuccess = `${getPercent(wonGames.length, games.length)}%`
	const averageDefeat = `${getPercent(games.length - wonGames.length, games.length)}%`

	const dateFilters = [
		<DateInput label="Date de début" source="startDate" alwaysOn />,
		<DateInput label="Date de fin" source="endDate" alwaysOn />
	]

	return (
		<div>
			<div className={classes.dashboard}>
				<StatCard title="Temps moyen (minutes)" subtitle={averageTime} />
				<StatCard title="Nombre d'enigmes moyen" subtitle={averageEnigmaTotal} />
				<StatCard title="Taux de réussite" subtitle={averageSuccess} />
				<StatCard title="Taux d'échec" subtitle={averageDefeat} />
			</div>
			<List {...fakeProps} bulkActionButtons={false} filters={dateFilters}>
				<Datagrid>
					<TextField source="id" />
					<TextField source="title" />
					<FunctionField label="Temps moyen (minutes)" render={record => getAverageEnigmaTime(record)}/>
					<FunctionField label="Phase 1: Taux de réussite" render={record => getAveragePhaseSuccess("isFirstPhaseSucceed")(record)}/>
					<FunctionField label="Phase 1: Taux d'échec" render={record => getAveragePhaseFailure("isFirstPhaseSucceed")(record)}/>
					<FunctionField label="Phase 2: Taux de réussite" render={record => getAveragePhaseSuccess("isSecondPhaseSucceed")(record)}/>
					<FunctionField label="Phase 2: Taux d'échec" render={record => getAveragePhaseFailure("isSecondPhaseSucceed")(record)}/>
					<FunctionField label="Phase 1: Nombre d'indices" render={record => getAveragePhaseClue("firstPhaseClueTotal")(record)}/>
					<FunctionField label="Phase 2: Nombre d'indices" render={record => getAveragePhaseClue("secondPhaseClueTotal")(record)}/>
				</Datagrid>
			</List>
			<StatChart games={games}/>
		</div>
	)
}