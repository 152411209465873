import {
	Create,
	TabbedForm,
} from "react-admin"
import { useLocation } from "react-router-dom"
import { FormToolbar } from "../components/button"
import { ChatAnswer } from "./chatAnswers/chatAnswer"
import { ChatCommonData } from "./chatCommonData/chatCommonData"
import { ChatEvent } from "./chatEvents/chatEvent"
import { ChatLinkedMessage } from "./chatLinkedMessages/chatLinkedMessage"
import { ChatPreviousMessage } from "./chatLinkedMessages/chatPreviousMessage"

export const ChatCreate = (props) => {
	const location = useLocation()
	const parent_id =
		location.state && location.state.record
			? location.state.record.parent_id
			: undefined

	const topic =
		location.state && location.state.record
			? location.state.record.topic
			: undefined

			
	return (
		<>
			<Create {...props}>
				<TabbedForm toolbar={<FormToolbar />} initialValues={{ topic, parentId: parent_id }}>
					<ChatCommonData />
					<ChatLinkedMessage />
					<ChatPreviousMessage />
					<ChatAnswer />
					<ChatEvent />
				</TabbedForm>
			</Create>
		</>
	)
}
