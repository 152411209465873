import * as React from "react"
import {
	Edit,
	TabbedForm,
} from "react-admin"
import { FormToolbar } from "../components/button"
import { ChatAnswer } from "./chatAnswers/chatAnswer"
import { ChatCommonData } from "./chatCommonData/chatCommonData"
import { ChatEvent } from "./chatEvents/chatEvent"
import { ChatLinkedMessage } from "./chatLinkedMessages/chatLinkedMessage"
import { ChatPreviousMessage } from "./chatLinkedMessages/chatPreviousMessage"

export const ChatEdit = (props) => {
	return (
		<Edit {...props}>
			<TabbedForm toolbar={<FormToolbar delete={true} />} {...props} >
				<ChatCommonData />
				<ChatLinkedMessage />
				<ChatPreviousMessage />
				<ChatAnswer />
				<ChatEvent />
			</TabbedForm>
		</Edit>
	)
}
