import React from "react"
import {
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	List,
	TextField,
	TopToolbar
} from "react-admin"

import sx from "../utils/sx"

const Actions = () => <TopToolbar>
	<CreateButton label="Ajouter" />
</TopToolbar>

export const UserList = (props) => {
	return (
		<List {...props}
			actions={<Actions />}
			title="Liste des utilisateurs"
		>
			<Datagrid
				rowClick="edit"
				bulkActionButtons={false}
				sx={sx.datagridColumns}
			>
				<TextField
					source="firstName"
					label="Prénom"
				/>
				<TextField
					source="lastName"
					label="Nom"
				/>
				<TextField
					source="email"
					label="Email"
				/>
				<DateField
					source="createdAt"
					label="Créé le"
				/>
				<DateField
					source="updatedAt"
					label="Mis à jour le"
				/>
				<EditButton
					label=""
					size="large"
					sx={sx.datagridButton}
				/>
			</Datagrid>
		</List>
	)
}
