import * as React from "react"
import numeral from "numeral"
import dayjs from "dayjs"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { MenuItem, Select, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

const weekOfYear = require("dayjs/plugin/weekOfYear")
const useStyles = makeStyles({
	wrapper: {
		display: "flex",
		gap: "30px",
		marginTop: "4rem"
	},
	chart: {
		maxHeight: "600px",
		marginTop: "2rem"
	}
})

dayjs.extend(weekOfYear)

export const StatChart = (props) => {
	const { games } = props
	const [type, setType] = useState("hour")
	const [selectedDate, setSelectedDate] = useState(dayjs())
	const classes = useStyles()
	const getStats = (formatDate) => (stats, current) => {
		const date = formatDate(current.creationDate)

		return stats[date]
			? { ...stats, [date]: numeral(stats[date]).add(1).value() }
			: { ...stats, [date]: 1 }
	}
	const settings = {
		"hour": {
			labels: [
				"00h", "01h", "02h", "03h", "04h", "05h", "06h", "07h", "08h", "09h", "10h", "11h",
				"12h", "13h", "14h", "15h", "16h", "17h", "18h", "19h", "20h", "21h", "22h", "23h"
			],
			format: (date) => `${dayjs(date).format("HH")}h`,
			filter: (game) => {
				return dayjs(game.creationDate).format("DD-MM-YYYY") === dayjs(selectedDate).format("DD-MM-YYYY")
			},
			title: `Nombre de parties jouées le ${dayjs(selectedDate).format("DD/MM/YYYY")} par heure`
		},
		"day": {
			labels: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
			format: (date) => {
				const labels = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
				return labels[dayjs(date).get("day")]
			},
			filter: (game) => (
				dayjs(game.creationDate).week() === dayjs(selectedDate).week()
				&& dayjs(game.creationDate).format("YYYY") === dayjs(selectedDate).format("YYYY")
			),
			title: `Nombre de parties jouées la semaine du ${dayjs(selectedDate).format("DD/MM/YYYY")} par jour`
		},
		"month": {
			labels: [
				"Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre",
				"Novembre", "Décembre"
			],
			format: (date) => settings[type].labels[dayjs(date).get("month")],
			filter: (game) => dayjs(game.creationDate).format("YYYY") === dayjs(selectedDate).format("YYYY"),
			title: `Nombre de parties jouées en ${dayjs(selectedDate).format("YYYY")} par mois`
		},
		year: {
			labels: [],
			format: (date) => dayjs(date).get("year"),
			filter: () => true,
			title: `Nombre de parties jouées par année`
		}
	}
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: settings[type].title,
			}
		}
	}
	const data = {
		labels: settings[type].labels,
		datasets: [
			{
				label: "Nombre de parties jouées",
				data: games.filter(settings[type].filter).reduce(getStats(settings[type].format), {}),
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			}
		]
	}

	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	)

	return (
		<div>
			<div className={classes.wrapper}>
				<Select
					value={type}
					onChange={(event) => setType(event.target.value)}
				>
					<MenuItem value="hour">Par heure</MenuItem>
					<MenuItem value="day">Par jour</MenuItem>
					<MenuItem value="month">Par mois</MenuItem>
					<MenuItem value="year">Par année</MenuItem>
				</Select>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={selectedDate}
						onChange={(newDate) => setSelectedDate(newDate)}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
			</div>
			<Bar className={classes.chart} options={options} data={data} />
		</div>
	)
}