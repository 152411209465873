import { makeStyles } from "@material-ui/core"
import {
	ArrayInput,
	FormTab,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton, getCustomRemoveButton } from "../../components/button"
import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		width: "25%"
	},
	hidden: {
		display: "none"
	}
})

export const EnigmaClue = (props) => {
	const classes = useStyles()

	return (
		<FormTab label="Message d'aide de l'énigme" {...props}>
			<TextInput
				className={classes.hidden}
				disabled
				source="enigmaClue.id"
				label="Id de l'indice"
			/>
			<TextInput
				className={classes.textarea}
				multiline
				rows="5"
				source="enigmaClue.request"
				label="Demande du joueur"
			/>
			<TextInput
				className={classes.hidden}
				disabled
				source="enigmaClue.advice.id"
				label="Id de la réponse"
			/>
			<TextInput
				className={classes.textarea}
				multiline
				rows="5"
				source="enigmaClue.advice.content"
				label="Réponse du personnage"
			/>
			<MediaField
				source="enigmaClue.advice.mediaContent"
				label="media"
				className={classes.textarea}
			/>
			<ReferenceInput
				source="enigmaClue.advice.character"
				reference="characters"
				label="Personnage"
				className={classes.textarea}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<ArrayInput
				name="answers"
				source="enigmaClue.advice.answers"
				label=""
			>
				<SimpleFormIterator 
					disableReordering 
					addButton={getCustomAddButton("Ajouter une réponse du joueur")} 
					removeButton={getCustomRemoveButton("Supprimer")}
				>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id de la réponse"
					/>
					<TextInput
						className={classes.textarea}
						multiline
						rows="5"
						source="text"
						label="Texte de la réponse"
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
