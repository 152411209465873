import * as React from "react"
import {
	List,
	Datagrid,
	ImageField,
	EditButton,
	CreateButton,
	TextField
} from "react-admin"
import { makeStyles,  } from "@material-ui/core"

const useStyles = makeStyles({
	small: {
		maxWidth: "50px"
	}
})

export const AvatarList = (props) => {
	const classes = useStyles()

	return (
		<>
			<List {...props} pagination={false} actions={<CreateButton label="Ajouter"/>}>
				<Datagrid rowClick="edit">
					<ImageField className={classes.small} label="Avatar" source="media.url" />
					<TextField label="Description" source="media.alt" />
					<EditButton label="Editer" />
				</Datagrid>
			</List>
		</>
	)
}
