import { makeStyles } from "@material-ui/core"
import {
	ArrayInput,
	FormTab,
	RadioButtonGroupInput,
	ReferenceInput,
	SelectInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"
import { getCustomAddButton } from "../../components/button"
import { MediaField } from "../../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
		width: "25%"
	},
	hidden: {
		display: "none"
	}
})

export const EnigmaCaption = (props) => {
	const classes = useStyles()

	return (
		<FormTab label="Dialogues" {...props}>
			<TextInput
				className={classes.hidden}
				disabled
				source="caption.id"
				label="Id du dialogue"
			/>
			<ReferenceInput
				source="caption.character"
				reference="characters"
				label="Personnage"
				className={classes.textarea}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<TextInput
				className={classes.textarea}
				multiline
				rows="5"
				name="caption.content"
				label="Message de la légende"
				source="content"
			/>
			<MediaField
				name="caption.media"
				source="caption.media"
				label="media"
				className={classes.textarea}
				credit={true}
				creditClassname={classes.textarea}
				creditSource="caption.media.credit"
				creditLabel="Crédit de l'image"
				alt={true}
				altClassname={classes.textarea}
				altSource="caption.media.alt"
				altLabel="Alt de l'image"
			/>
			<ArrayInput
				name="caption.choices"
				source="caption.choices"
				label="Légendes"
			>
				<SimpleFormIterator disableReordering addButton={getCustomAddButton("Ajouter une légende")}>
					<TextInput
						className={classes.hidden}
						disabled
						source="id"
						label="Id de la légende"
					/>
					<TextInput
						className={classes.textarea}
						rows="5"
						multiline
						source="content"
						label="Texte de la légende"
					/>
					<RadioButtonGroupInput
						source="real"
						label="Vrai/Faux"
						choices={[
							{ id: true, name: "oui" },
							{ id: false, name: "non" }
						]}
					/>
					<TextInput
						className={classes.textarea}
						rows="5"
						multiline
						source="feedback.content"
						label="Feedback pour la sélection de cette légendes"
					/>
					<MediaField
						source="feedback.media"
						label="media"
						className={classes.textarea}
						creditClassname={classes.textarea}
						creditSource="feedback.media.credit"
						creditLabel="Crédit de l'image"
						altClassname={classes.textarea}
						altSource="feedback.media.alt"
						altLabel="Alt de l'image"
					/>
					<ReferenceInput
						source="character"
						reference="characters"
						label="Personnage"
						className={classes.textarea}
					>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}
