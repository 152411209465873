import { makeStyles } from "@material-ui/core"
import {
	ArrayInput,
	AutocompleteArrayInput,
	FormDataConsumer,
	FormTab,
	ReferenceArrayInput,
	SimpleFormIterator,
	TextInput
} from "react-admin"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
		width: "25%"
	}
})

export const ChatEvent = (props) => {
	const classes = useStyles()

	return (
		<FormTab label="Evenements" {...props}>
			<FormDataConsumer>
				{({formData}) => {
					return <ReferenceArrayInput
						className={classes.textarea}
						source="events.messages"
						reference="chats"
						label="Message"
						filter={{topic: formData.topic}}
					>
						<AutocompleteArrayInput optionText="content"/>
					</ReferenceArrayInput>
				}}
			</FormDataConsumer>
			<ReferenceArrayInput
				className={classes.textarea}
				source="events.toolbox"
				reference="toolbox"
				label="Toolbox"
			>
				<AutocompleteArrayInput optionText="title"/>
			</ReferenceArrayInput>
			<ArrayInput
				source="events.customs"
				label=""
			>
				<SimpleFormIterator disableReordering>
					<TextInput 
						label="Evènement personnalisé" 
						className={classes.textarea}
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</FormTab>
	)
}