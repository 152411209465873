import FaceIcon from "@material-ui/icons/Face"
import MessageIcon from "@material-ui/icons/Message"
import SearchIcon from "@material-ui/icons/Search"
import TitleIcon from "@mui/icons-material/Title"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import BuildIcon from "@material-ui/icons/Build"
import SpeakerNotes from "@material-ui/icons/SpeakerNotes"
import AccountCircle from "@material-ui/icons/AccountCircle"
import PersonIcon from "@material-ui/icons/Person"

import * as React from "react"
import { Admin, Resource } from "react-admin"
import { reducer as tree } from "ra-tree-ui-materialui"
import dataProvider from "./apiProvider"

import { Dashboard } from "./Dashboard"
import authProvider from "./authProvider"

import { ToolboxList } from "./toolboxes/toolboxList"
import { ToolboxCreate } from "./toolboxes/toolboxCreate"
import { ToolboxEdit } from "./toolboxes/toolboxEdit"

import { TopicsList } from "./topics/topicList"
import { TopicsCreate } from "./topics/topicCreate"
import { TopicsEdit } from "./topics/topicEdit"

import { NewsList } from "./news/newsList"
import { NewsCreate } from "./news/newsCreate"
import { NewsEdit } from "./news/newsEdit"

import { EnigmaList } from "./enigmas/enigmaList"
import { EnigmaCreate } from "./enigmas/enigmaCreate"
import { EnigmaEdit } from "./enigmas/enigmaEdit"

import { ChatEdit } from "./chats/chatEdit"
import { ChatCreate } from "./chats/chatCreate"
import { ChatList } from "./chats/chatList"

import { CharacterList } from "./characters/characterList"
import { CharacterCreate } from "./characters/characterCreate"
import { CharacterEdit } from "./characters/characterEdit"

import { AvatarList } from "./avatars/avatarList"
import { AvatarCreate } from "./avatars/avatarCreate"
import { AvatarEdit } from "./avatars/avatarEdit"

import { UserList } from "./users/userList"
import { UserCreate } from "./users/userCreate"
import { UserEdit } from "./users/userEdit"

import { RecapEdit } from "./recaps/recapEdit"
import { CustomLayout } from "./CustomLayout"

const App = () => (
	<Admin
		layout={CustomLayout}
		dashboard={Dashboard}
		dataProvider={dataProvider}
		authProvider={authProvider}
		customReducers={{ tree }}
	>
		<Resource name="games"/>

		<Resource
			icon={SearchIcon}
			name="enigmas"
			options={{ label: 'Enigmes' }}
			list={EnigmaList}
			create={EnigmaCreate}
			edit={EnigmaEdit}
		/>

		<Resource
			icon={FaceIcon}
			name="characters"
			options={{ label: 'Personnages' }}
			list={CharacterList}
			create={CharacterCreate}
			edit={CharacterEdit}
		/>

		<Resource
			icon={MessageIcon}
			name="chats"
			options={{ label: 'Messages' }}
			list={ChatList}
			create={ChatCreate}
			edit={ChatEdit}
		/>

		<Resource
			icon={AnnouncementIcon}
			name="news"
			options={{ label: 'News' }}
			list={NewsList}
			create={NewsCreate}
			edit={NewsEdit}
		/>

		<Resource
			icon={BuildIcon}
			name="toolbox"
			options={{ label: 'Boite à outils' }}
			list={ToolboxList}
			create={ToolboxCreate}
			edit={ToolboxEdit}
		/>

		<Resource
			icon={TitleIcon}
			name="topics"
			options={{ label: 'Sujet des messages' }}
			list={TopicsList}
			create={TopicsCreate}
			edit={TopicsEdit}
		/>

		<Resource
			icon={SpeakerNotes}
			name="recaps"
			options={{ label: 'Recap de fin de partie' }}
			edit={RecapEdit}
		/>

		<Resource
			icon={AccountCircle}
			name="avatars"
			options={{ label: 'Avatars' }}
			list={AvatarList}
			create={AvatarCreate}
			edit={AvatarEdit}
		/>

		<Resource
			icon={PersonIcon}
			name="users"
			options={{ label: 'Utilisateurs' }}
			list={UserList}
			create={UserCreate}
			edit={UserEdit}
		/>
	</Admin>
)

export default App
