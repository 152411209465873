import * as React from "react"
import { Create, SimpleForm } from "react-admin"
import { makeStyles } from "@material-ui/core"

import { FormToolbar } from "../components/button"
import { MediaField } from "../components/mediaField"

const useStyles = makeStyles({
	textarea: {
		display: "flex",
		width: "25%"
	}
})

export const AvatarCreate = (props) => {
	const classes = useStyles()

	return <Create {...props}>
		<SimpleForm toolbar={<FormToolbar />}>
			<MediaField 
				label="avatar"
				className={classes.textarea}
				source="media" 
				accept="image/*"
				alt={true}
				altSource="media.alt"
				altLabel="Description de l'avatar"
				altClassname={classes.textarea}
			/>
		</SimpleForm>
	</Create>
}
