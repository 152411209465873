import React from "react"
import { Box } from "@mui/material"
import {
	Edit,
	PasswordInput,
	required,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"

import { emailValidator } from "../utils/email-validator"
import { Separator } from "../utils/Separator"
import sx from "../utils/sx"
import FormGrid from "../utils/FormGrid"

export const UserEdit = (props) => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	const validateUserEdit = (values) => {
		const errors = {}

		if (values.password !== values.passwordConfirmation) {
			errors.passwordConfirmation = "Les mots de passe ne sont pas les même"
		}

		if (!emailValidator.test(values.email)) {
			errors.email = "Adresse email invalide"
		}

		return errors
	}

	const onSuccess = (data) => {
		notify(
			"Fonction %{firstName} %{lastName} modifié",
			{
				type: "info",
				messageArgs: {
					firstName: data.firstName,
					lastName: data.lastName
				}
			}
		)
		redirect("/users")
		refresh()
	}

	return (
		<Edit {...props}
			title="Modification d'un utilisateur"
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
		>
			<SimpleForm validate={validateUserEdit}>
				<Box sx={sx.prose}>
					<TextInput
						source="firstName"
						label="Prénom"
						validate={required()}
						fullWidth
					/>
					<TextInput
						source="lastName"
						label="Nom"
						validate={required()}
						fullWidth
					/>
					<TextInput
						source="email"
						label="Email"
						validate={required()}
						fullWidth
						type="email"
					/>
					<Separator/>
					<FormGrid>
						<PasswordInput
							source="password"
							label="Mot de passe"
							fullWidth
						/>
						<PasswordInput
							source="passwordConfirmation"
							label="Confirmation de mot de passe"
							fullWidth
						/>
					</FormGrid>
				</Box>
			</SimpleForm>
		</Edit>
	)
}
