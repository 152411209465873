import simpleRestProvider from "ra-data-simple-rest"
import { fetchUtils } from "react-admin"

import chatProvider from "./chats/chatProvider"
import enigmaProvider from "./enigmas/enigmaProvider"
import recapProvider from "./recaps/recapProvider"
import { env } from "./utils/env"

import clearFrontendCache from "./clearFrontendCache"

const httpClient = (url, options = {}) => {
	options.headers = new Headers({ Accept: "application/json" })

	const token = localStorage.getItem("token")

	if (token) {
		options.headers.set("Authorization", `Bearer ${token}`)
	}

	return fetchUtils.fetchJson(url, options)
}

const commonDataProvider = simpleRestProvider(env().apiUrl, httpClient)

const withFrontendCacheClear = (originalAction) => {
	return (resource, params) => {
		return originalAction(resource, params).then((result) => {
			clearFrontendCache()
			return result
		})
	}
}

const apiProvider = {
	...commonDataProvider,
	create: withFrontendCacheClear(async (resource, params) => {

		if (resource === "enigmas") {
			return enigmaProvider.create(resource, params)
		}

		if (resource === "chats") {
			return chatProvider.create(resource, params)
		}

		if (resource === "recaps") {
			return recapProvider.create(resource, params)
		}

		if (!params.data.media && !params.data.slides) {
			// fallback to the default implementation
			return commonDataProvider.create(resource, params)
		}

		if (params.data.media) {
			if (params.data.media.url && !params.data.media.rawFile) {
				return commonDataProvider.create(resource, {
					...params,
					data: {
						...params.data,
						media: {
							url: params.data.media.url
						}
					}
				})
			}
			return convertFileToBase64(params.data.media).then((base64Media) =>
				commonDataProvider.create(resource, {
					...params,
					data: {
						...params.data,
						media: {
							src: base64Media,
							title: params.data.media.title,
							alt: params.data.media.alt ? params.data.media.alt : null
						}
					}
				})
			)
		} else {
			const slides = await buildSlidesData(params.data.slides)
			return commonDataProvider.create(resource, {
				...params,
				data: {
					...params.data,
					slides
				}
			})
		}
	}),
	update: withFrontendCacheClear(async (resource, params) => {
		if (resource === "enigmas") {
			return enigmaProvider.update(resource, params)
		}

		if (resource === "chats")  {
			return chatProvider.update(resource, params)
		}

		if (resource === "recaps") {
			return recapProvider.update(resource, params)
		}

		if (
			(!params.data.media || params.data.media.id) &&
			!params.data.slides
		) {
			// fallback to the default implementation
			return commonDataProvider.update(resource, params)
		}

		if (params.data.media) {
			return convertFileToBase64(params.data.media).then((base64Media) =>
				commonDataProvider.update(resource, {
					...params,
					data: {
						...params.data,
						media: {
							src: base64Media,
							title: params.data.media.title,
							alt: params.data.media.alt ? params.data.media.alt : null
						}
					}
				})
			)
		} else {
			const slides = await editSlidesData(params.data.slides)
			return commonDataProvider.update(resource, {
				...params,
				data: {
					...params.data,
					slides
				}
			})
		}
	})
}

const editSlidesData = async (slides) => {
	return Promise.all(
		slides.map(async (slide) => {
			if (!slide.media) {
				return {
					id: slide.id,
					description: slide.description,
					media: null
				}
			}

			if (slide.media.id) {
				return slide
			}

			const base64Media = await convertFileToBase64(slide.media)

			return {
				id: slide.id,
				description: slide.description,
				media: {
					src: base64Media,
					title: slide.media.title
				}
			}
		})
	)
}

const buildSlidesData = async (slides) => {
	return Promise.all(
		slides.map(async (slide) => {
			if (!slide.media) {
				return {
					description: slide.description,
					media: null
				}
			}

			if (slide.media.id) {
				return slide
			}

			const base64Media = await convertFileToBase64(slide.media)

			return {
				description: slide.description,
				media: {
					src: base64Media,
					title: slide.media.title
				}
			}
		})
	)
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = async (file) =>
	new Promise(async (resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => resolve(reader.result)
		reader.onerror = reject

		// const res = await fetch(file.url, { mode: "no-cors" })

		reader.readAsDataURL(file.rawFile)
	})

export default apiProvider
