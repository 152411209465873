import * as React from "react"
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	CreateButton
} from "react-admin"

export const CharacterList = (props) => (
	<>
		<List {...props} pagination={false} actions={<CreateButton label="Ajouter"/>}>
			<Datagrid rowClick="edit">
				<TextField label="nom du personnage" source="name" />
				<EditButton label="Editer" />
			</Datagrid>
		</List>
	</>
)
